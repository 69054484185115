import {
  ChangeDetectionStrategy,
  Component,
  Injectable,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Resolve } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import {
  Observable,
  Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  NgRedux,
  select,
} from '@angular-redux/store';

import { IAppState } from '../../../redux/store';
import { SettingsController } from '../../../redux/actions/settings/settings.controller';
import { SettingsSelector } from '../../../redux/reducers/settings.reducer';
import { SettingsApiService } from '../../../services/api/settings.api.service';
import { ISettingsObject } from '../../../../../../common/interfaces/settings';

import { version } from '../../../../../../package.json';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  public static ROUTE = 'settings';
  private ngDestroy$ = new Subject<never>();

  @select(SettingsSelector.getDatas) settings$: Observable<any>;

  public allowedMimetypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
  ];
  public form: FormGroup = this.fb.group({
    remotePrimaryColor: '',
    remoteFontFamily: '',
    remoteLogo: null,
  });
  public VERSION = version;

  private isLogoFile = false;

  constructor(
    private fb: FormBuilder,
    private apiService: SettingsApiService,
    private toastrService: ToastrService,
  ) {}

  ngOnInit() {
    this.settings$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(({
        remote_primary_color,
        remote_font_family,
        remote_logo,
      }: ISettingsObject) => {
        this.form.setValue({
          remotePrimaryColor: remote_primary_color || '',
          remoteFontFamily: remote_font_family || '',
          remoteLogo: remote_logo ? JSON.parse(remote_logo) : null,
        });
      });
  }

  ngOnDestroy() {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  onRemoteLogoChange(file: File) {
    if (file) {
        this.form.get('remoteLogo').setValue(file);
        this.isLogoFile = true;
    } else {
      this.isLogoFile = false;
      this.form.get('remoteLogo').setValue(null);
    }
  }

  validateForm() {
    if ([this.form.value.remoteLogo].some(v => !v)) {
      this.toastrService.error('', 'Felder mit * sind Pflichtfelder.');
      return false;
    }

    return true;
  }

  async onSubmit() {
    if (!this.validateForm()) {
      return;
    }
    const {
      remotePrimaryColor,
      remoteFontFamily,
      remoteLogo,
    } = this.form.value;

    const settingsObject = {
      remote_primary_color: remotePrimaryColor,
      remote_font_family: remoteFontFamily,
      remote_logo: remoteLogo instanceof File ? remoteLogo : JSON.stringify(remoteLogo),
    };

    try {
      await this.apiService.updateSettings(settingsObject, this.isLogoFile);
      this.toastrService.success('', 'Einstellungen geändert');
    } catch (error) {
      this.toastrService.error('', 'Etwas ist schief gelaufen');
      throw error;
    }
  }
}

@Injectable()
export class SettingsDataResolver implements Resolve<any> {
  constructor(private redux: NgRedux<IAppState>, private settingsController: SettingsController) {
  }

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.settingsController.updateActive());
  }
}
