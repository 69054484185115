import { Injectable } from '@angular/core';
import Keyboard from 'simple-keyboard';

interface KeyboardInstance {
  keyboardRef: Keyboard;
  prevButton?: string;
}

interface KeyboardOptions {
  layout?: string;
  onChange?: (input: string) => void;
  onKeyPress?: (button: string) => void;
}

interface KeyboardMap {
  layouts: Array<string>;
  switchBackAfterKeyPress: boolean;
}

const layouts = {
  'default': [
    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    '{tab} q w e r t y u i o p [ ] \\',
    '{lock} a s d f g h j k l ; \' {enter}',
    '{shift} z x c v b n m , . / {shift}',
    '.com @ {space}',
  ],
  'aachen_default': [
    '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
    '{tab} q w e r t z u i o p \u00FC +',
    '{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}',
    '{shift} < y x c v b n m , . - {shift}',
    '€ {space} @',
  ],
  'aachen_shift': [
    '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
    '{tab} Q W E R T Z U I O P \u00DC *',
    '{lock} A S D F G H J K L \u00D6 \u00C4 \' {enter}',
    '{shift} > Y X C V B N M ; : _ {shift}',
    '€ {space} @',
  ],
};

@Injectable({ providedIn: 'root' })
export class KeyboardRenderer {
  private instances: Record<string, KeyboardInstance> = {};
  private switchKeyboardMap: Record<string, KeyboardMap> = {
    '{lock}': {
      layouts: ['aachen_default', 'aachen_shift'],
      switchBackAfterKeyPress: false,
    },
    '{shift}': {
      layouts: ['aachen_default', 'aachen_shift'],
      switchBackAfterKeyPress: true,
    },
  };

  public initKeyboards(options: KeyboardOptions = {}): void {
    this.destroyKeyboards();
    const keyboards = document.querySelectorAll('.keyboard-container');

    keyboards.forEach((keyboard) => {
      const keyboardRef = keyboard.querySelector('[keyboard]');
      if (!keyboardRef) {
        console.warn(`[KeyboardRenderer] Keyboard reference not found.`);
        return;
      }
      const inputRef = keyboard.querySelector('input');
      if (!inputRef) {
        console.warn(`[KeyboardRenderer] Input reference not found.`);
        return;
      }
      const id = keyboardRef.getAttribute('id');

      try {
        this.instances[id] = {
          keyboardRef: new Keyboard(`.keyboard-wrapper #${id}`,
            {
              baseClass: id,
              layout: layouts,
              layoutName: options.layout || 'default',
              mergeDisplay: true,
              display: {
                '{bksp}': '\u232b',
                '{tab}': '\u21C6',
                '{enter}': '\u23CE',
                '{lock}': '\u21EA',
                '{shift}': '\u21E7',
              },
              onChange: (input) => this.onChange(inputRef, input, options.onChange),
              onKeyPress: (button) => this.onKeyPress(button, id, options.onKeyPress),
            },
          ),
        };
        inputRef.addEventListener('input', (e) => {
          this.instances[id].keyboardRef.setInput((e.target as HTMLInputElement).value);
        });
      } catch (err) {
        console.log('KeyboardRenderer', err);
      }
    });
  }

  public destroyKeyboards(): void {
    Object.values(this.instances).forEach(({ keyboardRef }) => keyboardRef.destroy());
    this.instances = {};
  }

  private onChange(inputRef: HTMLInputElement, value: string, callback?: KeyboardOptions['onChange']): void {
    inputRef.value = value;

    if (callback) {
      callback(value);
    }
  }

  private onKeyPress(button: string, id: string, callback?: KeyboardOptions['onKeyPress']): void {
    const { keyboardRef, prevButton } = this.instances[id];

    const switcher = this.switchKeyboardMap[button];
    if (switcher) {
      this.toggleLayout(keyboardRef, switcher);
    }

    const switcher2 = this.switchKeyboardMap[prevButton];
    if (prevButton === '{shift}' && switcher2 && switcher2.switchBackAfterKeyPress) {
      this.toggleLayout(keyboardRef, switcher2);
    }

    this.instances[id].prevButton = button === prevButton ? '' : button;

    if (callback) {
      callback(button);
    }
  }

  private toggleLayout(keyboardRef: Keyboard, switcher: KeyboardMap): void {
    const currentLayout = keyboardRef.options.layoutName;
    const switcherLayouts = switcher.layouts;

    keyboardRef.setOptions({
      layoutName: switcherLayouts[0] === currentLayout ? switcherLayouts[1] : switcherLayouts[0],
    });
  }
}
