import {
  IId,
  IName,
  Omit,
} from './util';
import {
  FileId,
  IApiFile,
  IApiFileUnparsed,
  IApiGetFile,
  IApiSetFile,
} from './file';
import {
  IApiGetLocation,
  IApiSetBookingLocation,
  LocationId,
} from './location';
import { FILE_MEDIA_FIELDNAME_PREFIX } from '../constants';

export interface IApiBookingInputs {
  value: string;
  type: string;
  name: string;
  fileKey?: string;
}

export enum BookingsStatusEnum {
  WAITING = 'waiting',
  PROCESSING = 'processing',
  ACTIVE = 'active',
  DRAFT = 'draft',
  PREVIEW = 'preview',
  ERROR = 'error',
}

export enum BookingsTypeEnum {
  BOOKING = 'BOOKING',
  PREVIEW = 'PREVIEW',
}

export enum DisplayMode {
  Priority = 'PRIORITY',
  Standard = 'STANDARD',
  Fallback = 'FALLBACK',
  Category = 'CATEGORY',
}

interface IApiBookingBase extends IName {
  display_mode: DisplayMode;
  locations_group_name?: string | null;
  is_no_end: boolean;
  start_date: string;
  end_date?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  duration: number;
  external_id: string | null;
  category_id: number | null;
  week_days: string | Array<string> | null;
  createdAt: string;
  updatedAt: string;
}

export interface IApiSetBooking extends IApiBookingBase {
  inputs: { [key: number]: IApiBookingInputs };
  is_daily: boolean;
  files: Array<IApiSetFile>;
  files_data?: Array<IApiSetFile>;
  timezoneId: string;
  booking_template: number;
  locations: Array<IApiSetBookingLocation | number>;
  locations_ids?: Array<IApiSetBookingLocation | number>;
  medias: Record<string, any> | string;
  is_preview: boolean;
}

export interface IApiGetBooking extends IApiBookingBase, IId {
  inputs: Array<IApiBookingInputs>;
  is_daily: boolean;
  booking_template: IApiGetBookingTemplate;
  files: IApiGetFile[];
  locations: IApiGetLocation[];
  thumbnail_url: string | null;
  screenshot_url: string | null;
  category: IApiGetBooking;
  week_days: Array<string>;
  status: BookingsStatusEnum;
}

export interface ISetBookingsProcessing extends IApiBookingBase {
  inputs: { [key: number]: IApiBookingInputs };
  id: number | string;
  booking_template: IApiGetBookingTemplate;
  files: IApiGetFile[];
  locations: IApiGetLocation[];
  thumbnail_url: string | null;
  screenshot_url: string | null;
  week_days: Array<string>;
}

export interface IApiSetBookingUnparsed extends IName {
  id: number | null;
  is_preview?: 'true' | 'false';
  is_draft: boolean;
  files: Array<IApiFileUnparsed>;
  display_mode: DisplayMode;
  locations_group_name?: string | null;
  data: string;
  start_date: string;
  end_date?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  timezoneId: string;
  booking_template: string;
  locations: string;
  duration: string;
  external_id: string | null;
  category_id: number | null;
  week_days: Array<string>;
  updatedAt: string;
  createdAt: string;
  [FILE_MEDIA_FIELDNAME_PREFIX]?: string | undefined;
}

export interface IApiSetBookingTemplateInputs extends IName {
  type: string;
  label?: string;
  maxRows?: number;
  addColorButton?: boolean;
  presetColors?: string[];
  hidden?: boolean;
  default?: string | number | boolean;
  isRequired?: boolean;
  mimetypes?: string[];
  items?: string[];
  placeholder?: string;
  fileKey?: string;
  validate?: (data: any) => boolean;
}

export interface IApiGetBookingTemplateInputs extends IApiSetBookingTemplateInputs {
  id: number;
}

interface IApiBookingTemplateBase extends IName {
  category: string;
  duration_calculator: string;
  display_modes: Array<string> | null;
  locations: Array<IApiGetLocation>;
}

export interface IApiSetBookingTemplate extends Omit<IApiBookingTemplateBase, 'locations'> {
  inputs_json: Array<IApiSetBookingTemplateInputs>;
  locations: Array<number>;
}

export interface IApiUpdateBookingTemplate extends IApiBookingTemplateBase {
  thumbnail?: number;
  template?: number;
  inputs_json: IApiSetBookingTemplateInputs[];
}

export interface IApiGetBookingTemplate extends IApiBookingTemplateBase, IId {
  inputs: IApiGetBookingTemplateInputs[];
  template: IApiFile;
  thumbnail: IApiFile;
  assets: IApiFile;
}

export interface IApiGetSimpleBookingTemplate extends IId, IApiBookingTemplateBase {
  template: number;
  thumbnail: number;
}

export interface IApiSetBookingTemplateUnparsed extends IName {
  inputs?: string;
  category?: string;
  duration_calculator?: string;
  files?: IApiFileUnparsed[];
  display_modes?: string;
  locations?: Array<number>;
}

export type BookingTemplateId = IId['id'];

export interface IBookingTemplateAttributes {
  id: BookingTemplateId;
  name: string | null;
  category: string | null;
  duration_calculator: string | null;
  thumbnail_id: FileId | null;
  template_id: FileId | null;
  assets_id: FileId | null;
  inputs_json: string | null;
  display_modes: string | null;
}

export type BookingTemplatePayload =
  Omit<IBookingTemplateAttributes, 'id' | 'inputs_json' | 'display_modes'>
  & { inputs_json: Array<IApiSetBookingTemplateInputs> | null }
  & { display_modes: Array<string> }
  & { locations: Array<number> };

export type BookingId = IId['id'];

export interface IBookingAttributes {
  id: BookingId;
  name: string | null;
  display_mode: DisplayMode;
  locations_group_name: string | null;
  is_no_end: number | null;
  is_daily: number | null;
  start_date: string | null;
  end_date: string | null;
  start_time: string | null;
  end_time: string | null;
  duration: number | null;
  booking_template_id: BookingTemplateId | null;
  inputs: string | Array<IApiBookingInputs> | null;
  inputs_json: string | Array<IApiBookingInputs> | null;
  thumbnail_url: string | null;
  screenshot_url: string | null;
  external_id: string | null;
  category_id: number | null;
  status: BookingsStatusEnum;
  type: BookingsTypeEnum;
  week_days: string | Array<string> | null;
}


export type BookingFileId = IId['id'];

export interface IBookingFileAttributes {
  id: BookingFileId;
  booking_id: BookingId;
  file_id: FileId;
}

export type BookingLocationId = IId['id'];

export interface IBookingLocationAttributes {
  id: BookingLocationId;
  booking_id: BookingId;
  location_id: LocationId;
}

export interface PreparedBookingData {
  booking_template: number;
  duration: number;
  end_date: string;
  end_time?: string;
  files: Array<{ file_id: { id: number; } }>;
  inputs_json: Array<Record<string, any>>;
  is_daily: boolean;
  is_no_end: boolean;
  display_mode: DisplayMode;
  locations_group_name: string | null;
  locations: Array<{ location_id: { id: number; } }>;
  name: string;
  screenshot_url?: string;
  start_date: string;
  start_time?: string;
  thumbnail_url?: string;
  external_id: string | null;
  category_id: number | null;
  week_days: string | null;
  is_preview: boolean;
  is_draft: boolean;
}

export interface IBookingForCategory extends IApiBookingBase {
  locations: Array<{ id: number }>;
}

export interface IBookingFile {
  encoding?: string;
  fieldname: string;
  id?: number;
  key: string;
  meta?: string;
  mimetype: string;
  name?: string;
  scope?: string;
  thumbnail_url: string;
  screenshot_url?: string;
}

export interface IBookingTemplateLocationsAttributes {
  id: number;
  booking_template_id: number;
  location_id: number;
}

export interface IBookingRuntime {
  is_no_end: boolean;
  start_date: string;
  end_date: string;
  is_daily: boolean;
  start_time: string;
  end_time: string;
}
